var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-for": "maintenance_kind",
                        label: _vm.$t("maintenance.labels.maintenanceKind"),
                      },
                    },
                    [
                      _c("b-select", {
                        attrs: {
                          id: "maintenance_kind",
                          "data-test": "maintenance_kind--select",
                          options: _vm.listMaintenanceKind,
                          state: _vm.validate(!!_vm.form.maintenanceKind),
                        },
                        model: {
                          value: _vm.form.maintenanceKind,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maintenanceKind", $$v)
                          },
                          expression: "form.maintenanceKind",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("maintenance.labels.scheduleDate"),
                        "label-for": "schedule_date",
                      },
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          "field-state": _vm.validate(!!_vm.form.scheduleDate),
                          hide: false,
                        },
                        model: {
                          value: _vm.form.scheduleDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "scheduleDate", $$v)
                          },
                          expression: "form.scheduleDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("maintenance.labels.scheduleHour"),
                        "label-for": "schedule_hour",
                      },
                    },
                    [
                      _c("b-select", {
                        attrs: {
                          id: "schedule_hour",
                          "data-test": "schedule_hour--select",
                          options: _vm.listHoursAvailable,
                          state: _vm.validate(!!_vm.form.scheduleHour),
                        },
                        model: {
                          value: _vm.form.scheduleHour,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "scheduleHour", $$v)
                          },
                          expression: "form.scheduleHour",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("maintenance.labels.garage"),
                        "label-for": "garage",
                      },
                    },
                    [
                      _c("b-select", {
                        attrs: {
                          id: "garage",
                          "data-test": "garage--select",
                          options: _vm.listGarage,
                          state: _vm.validate(!!_vm.form.garage),
                        },
                        model: {
                          value: _vm.form.garage,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "garage", $$v)
                          },
                          expression: "form.garage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.driverVerification
            ? _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("maintenance.labels.kmMarcks"),
                            "label-for": "km_marcks_id",
                          },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "mt-2",
                            attrs: {
                              id: "km_marcks_id",
                              type: "range",
                              min: "1000",
                              max: "150000",
                              value: "5000",
                              "data-test": "km_marcks_id",
                              list: "km_marcks",
                            },
                            on: { input: _vm.onInputRangeUpdateDistance },
                          }),
                        ],
                        1
                      ),
                      _c("datalist", { attrs: { id: "km_marcks" } }, [
                        _c("option", {
                          attrs: {
                            value: "1000",
                            title: "1 Km",
                            label: "1 Km",
                          },
                        }),
                        _c("option", {
                          attrs: { value: "5000", label: "5 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "10000", label: "10 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "15000", label: "10 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "20000", label: "10 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "25000", label: "10 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "30000", label: "10 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "35000", label: "10 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "40000", label: "10 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "45000", label: "10 Km" },
                        }),
                        _c("option", {
                          attrs: { value: "50000", label: "10 Km" },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pt-3", attrs: { cols: "3" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("template", { slot: "label" }, [_vm._v("   ")]),
                          _c(
                            "b-input-group",
                            { attrs: { prepend: "Km" } },
                            [
                              _c("b-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.formatDistance,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }