<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label-for="maintenance_kind"
            :label="$t('maintenance.labels.maintenanceKind')"
          >
            <b-select
              id="maintenance_kind"
              v-model="form.maintenanceKind"
              data-test="maintenance_kind--select"
              :options="listMaintenanceKind"
              :state="validate(!!form.maintenanceKind)"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group :label="$t('maintenance.labels.scheduleDate')" label-for="schedule_date">
            <date-picker
              v-model="form.scheduleDate"
              :field-state="validate(!!form.scheduleDate)"
              :hide="false"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group :label="$t('maintenance.labels.scheduleHour')" label-for="schedule_hour">
            <b-select
              id="schedule_hour"
              v-model="form.scheduleHour"
              data-test="schedule_hour--select"
              :options="listHoursAvailable"
              :state="validate(!!form.scheduleHour)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12">
          <b-form-group :label="$t('maintenance.labels.garage')" label-for="garage">
            <b-select
              id="garage"
              v-model="form.garage"
              data-test="garage--select"
              :options="listGarage"
              :state="validate(!!form.garage)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="driverVerification" class="mt-3">
        <b-col cols="6">
          <b-form-group :label="$t('maintenance.labels.kmMarcks')" label-for="km_marcks_id">
            <b-form-input
              id="km_marcks_id"
              class="mt-2"
              type="range"
              min="1000"
              max="150000"
              value="5000"
              data-test="km_marcks_id"
              list="km_marcks"
              @input="onInputRangeUpdateDistance"
            />
          </b-form-group>

          <datalist id="km_marcks">
            <option value="1000" title="1 Km" label="1 Km" />
            <option value="5000" label="5 Km" />
            <option value="10000" label="10 Km" />
            <option value="15000" label="10 Km" />
            <option value="20000" label="10 Km" />
            <option value="25000" label="10 Km" />
            <option value="30000" label="10 Km" />
            <option value="35000" label="10 Km" />
            <option value="40000" label="10 Km" />
            <option value="45000" label="10 Km" />
            <option value="50000" label="10 Km" />
          </datalist>
        </b-col>

        <b-col cols="3" class="pt-3">
          <b-form-group>
            <template slot="label">
              &nbsp;
            </template>
            <b-input-group prepend="Km">
              <b-input disabled :value="formatDistance" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateHours } from '@utils/list-hour';
import GarageStatusEnum from '@graphql/garages/enum/status';

export default {
  name: 'CreateSchedule',
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    driver: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    car: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    canValidate: false,
    garageDistance: 5000,
    listGarageByDistance: [],
    time: 0,
    form: {
      maintenanceKind: 'preventive',
      scheduleDate: new Date(),
      scheduleHour: '09:00',
      garage: '',
    },
  }),
  computed: {
    ...mapGetters('garage', {
      garageList: 'list'
    }),
    driverVerification() {
      return !!(this.driver && this.driver.address_latlong)
    },
    listHoursAvailable() {
      const listHours = generateHours(5, 23);

      return listHours.map(hour => ({
        text: hour,
        value: hour,
      }));
    },
    listMaintenanceKind() {
      return [
        {
          text: this.$t('maintenance.labels.maintenanceKindPreventive'),
          value: 'preventive'
        },
        {
          text: this.$t('maintenance.labels.maintenanceKindCorrective'),
          value: 'corrective',
          disabled: true
        },
      ];
    },
    listGarage() {
      return this.listGarageByDistance.map(item => ({
        value: item.id,
        text: this.mountGarageText(item),
      }));
    },
    formatDistance() {
      if (this.garageDistance) {
        return this.garageDistance / 1000;
      }

      return this.garageDistance;
    },
  },
  watch: {
    driver() {
      this.getGarageByDistance();
    },
  },
  mounted() {
    this.getGarageByDistance();
  },
  methods: {
    reset() {
      this.canValidate = false;
      this.form = {
        maintenanceKind: 'preventive',
        scheduleDate: '',
        scheduleHour: '09:00',
        garage: '',
      };
    },

    async getGarageByDistance() {
      const garageFilters = {
        schedule_types: {
          preventive: true
        }
      }

      if (this.driverVerification) {
        const latLong = this.driver.address_latlong.split(',').map(position => parseFloat(position));
        const distance = parseInt(this.garageDistance, 10);


        const garages = await this.$store.dispatch('garage/findByDistance', {
          latLong,
          distance,
          filter: garageFilters
        });

        this.listGarageByDistance = garages;
      } else {

        const garages = await this.$store.dispatch('garage/listAll', {
          variables: {
            filter: {
              ...garageFilters,
              status: GarageStatusEnum.ACTIVE,
            },
          },
        });

        this.listGarageByDistance = garages;
      }
    },

    onInputRangeUpdateDistance(value) {
      if (!value) {
        return;
      }

      clearTimeout(this.time);

      this.garageDistance = value;

      this.time = setTimeout(() => {
        this.getGarageByDistance();
      }, 500);
    },

    mountGarageText(garage) {
      return `[${this.writeCredential(garage.reseller_trusted)}] ${
        garage.name
      } - ${this.writeAddress(garage)}`;
    },

    writeCredential(resellerTrusted) {
      return resellerTrusted ? 'Credenciado' : 'Não credenciado';
    },

    writeAddress(address) {
      const {
        address_street_name,
        address_street_number,
        address_city,
        address_neighborhood,
        address_state,
        address_country,
      } = address;

      return `
        Filial de ${address_neighborhood} | ${address_street_name}
        ${address_street_number}, ${address_city}, ${address_state} - ${address_country}`;
    },

    validate(value) {
      if (!this.canValidate) {
        return null;
      }

      return value;
    },

    async newSchedule() {
      const validate = this.validateFields();

      if (validate) {
        return;
      }

      const configSwal = {
        type: 'warning',
        title: 'Realizar agendamento?',
        text: 'Ao realizar este agendamento o motorista será notificado.',
        showConfirmButton: true,
        confirmButtonText: 'Agendar',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
        showLoaderOnConfirm: true,
        preConfirm: () => this.scheduleIt(),
      };
      this.$swal(configSwal)
        .then(result => {
          if (result.dismiss) return;

          this.showSuccessAlert();
        })
        .catch(error => {
          this.$log.logError(error);
          this.showFailAlert();
        });
    },

    validateFields() {
      this.canValidate = true;
      return Object.values(this.form).some(item => !item);
    },

    async scheduleIt() {
      const dateRaw = this.$moment(this.form.scheduleDate).format('YYYY-MM-DD');
      const scheduleDate = `${dateRaw} ${this.form.scheduleHour}:00`;
      const date = this.$moment(scheduleDate).utc().format('YYYY-MM-DD HH:mm:ss');
      const odometer = await this.getOdometer();

      return this.$store
        .dispatch('schedule/scheduleIt', {
          variables: {
            scheduleId: this.schedule.id,
            driverId: this.driver?.id,
            bookingId: this.booking?.id,
            garageId: this.form.garage,
            date,
            odometer,
          },
        })
        .then(result => {
          this.$emit('scheduled', result);
          return result;
        });
    },

    showSuccessAlert() {
      const configSwalSuccess = {
        type: 'success',
        timer: 2000,
        showConfirmButton: false,
        title: `Agendado!`,
        text: 'Sucesso ao realizar o agendamento.',
        onClose: () => this.$root.$emit('bv::hide::modal', 'modal-create-schedule'),
      };

      this.$swal(configSwalSuccess);
    },

    showFailAlert() {
      const configSwalFail = {
        type: 'error',
        title: `Não foi possível realizar o agendamento`,
        text: '',
      };

      this.$swal(configSwalFail);
    },

    getOdometer() {
      return this.$store
        .dispatch('fleet/getByLicensePlate', {
          license_plate: this.car.license_plate,
        })
        .then(({ data }) => data.carTracking.odometer)
    },
  },
};
</script>
