<script>
export default {
  name: 'ContentLoading',
  props: {
    size: {
      type: String,
      default: 'regular',
    },
  },
  data: () => {
    return {
      networkError: false,
      loadingMessage: 'Carregando...',
      loadingSubMessage: ' ',
    }
  },
  mounted() {
    this.checkNetwork()
  },
  methods: {
    checkNetwork: function() {
      const self = this
      setTimeout(() => {
        setTimeout(() => {
          self.networkError = true
        }, 120000)
        self.loadingMessage = 'Ainda carregando...'
        self.loadingSubMessage = 'Parece estar demorando mais que o normal =/'
      }, 15000)
    },
  },
}
</script>

<template>
  <div :class="`notify-box ${size}`">
    <div v-if="networkError">
      <h1><i class="fa fa-unlink" /></h1>
      <h5>
        <slot name="error">
          Ocorreu um erro ao carregar esta página :(
        </slot>
      </h5>
      <h6>Caso necessite de alguma ajuda, abra uma SI.</h6>
    </div>
    <div v-else>
      <h1><i class="fa fa-spin fa-refresh" /></h1>
      <h5>{{ loadingMessage }}</h5>
      <h6>{{ loadingSubMessage }}</h6>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.regular,
.small {
  text-align: center;
  h6 {
    font-weight: normal;
    color: #666;
  }
}
.regular {
  padding: 60px 0;
  h1 {
    font-size: 40px;
  }
  h5 {
    margin-top: 30px;
  }
}
.small {
  padding: 10px 0;
  h1 {
    font-size: 30px;
  }
  h5 {
    margin-top: 10px;
  }
}
</style>
