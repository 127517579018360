var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", [
      _c("i", { staticClass: "fa fa-lock", attrs: { "aria-hidden": "true" } }),
      _vm._v(" " + _vm._s(_vm.$i18n.t("fleet.titles.blocks")) + ": "),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.getBlocks(
              _vm.trackerData.flags.vehicleLockedSafe || false,
              _vm.trackerData.flags.vehicleLockedByServer,
              _vm.trackerData.flags.vehicleLockedByToken
            )
          )
        ),
      ]),
    ]),
    _c("p", [
      _c("i", { staticClass: "fa fa-cog", attrs: { "aria-hidden": "true" } }),
      _vm._v(" " + _vm._s(_vm.$i18n.t("fleet.texts.tokenRegistered")) + ": "),
      _c("strong", [
        _vm._v(
          _vm._s(
            !_vm.trackerData.flags.tokenRegistered
              ? _vm.$i18n.t("fleet.texts.no")
              : _vm.$i18n.t("fleet.texts.yes")
          )
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }