var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalCreateSchedule",
      attrs: {
        id: "modal-create-schedule",
        size: "lg",
        title: _vm.$t("maintenance.titles.modalCreateSchedule"),
        "ok-title": _vm.$t("maintenance.titles.modalCreateScheduleOk"),
        "cancel-title": _vm.$t("maintenance.buttons.close"),
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.createSchedule.apply(null, arguments)
        },
        close: _vm.resetSchedule,
      },
    },
    [
      _c("create-schedule", {
        ref: "createScheduleComponent",
        attrs: {
          booking: _vm.booking,
          car: _vm.car,
          driver: _vm.driver,
          schedule: _vm.schedule,
        },
        on: { scheduled: (schedule) => _vm.$emit("scheduled", schedule) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }