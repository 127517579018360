var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "card-pointer",
      class: { "card-active": _vm.active },
      attrs: { id: _vm.getId },
      on: {
        click: function ($event) {
          return _vm.goTo(_vm.to)
        },
      },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "8", title: _vm.title } }, [
            _c("div", { staticClass: "h4 mb-0 font-weight-bold card-value" }, [
              _vm._v(" " + _vm._s(_vm.value) + " "),
            ]),
            _c(
              "small",
              {
                staticClass: "text-muted text-uppercase font-weight-bold",
                class: { "text-active": _vm.active },
              },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            ),
          ]),
          _c(
            "b-col",
            {
              staticClass: "text-muted text-right mb-0",
              class: { "text-center": _vm.subtitle },
              attrs: { cols: "4" },
            },
            [
              _vm.subtitle
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover",
                          value: _vm.subtitleHover,
                          expression: "subtitleHover",
                          modifiers: { hover: true },
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "h4 mb-0 font-weight-bold card-value" },
                        [
                          _c("i", {
                            class: {
                              [_vm.icon]: _vm.icon,
                              "icon-active": _vm.active,
                              h3: !_vm.subtitle,
                            },
                          }),
                        ]
                      ),
                      _c(
                        "small",
                        {
                          staticClass:
                            "text-muted text-uppercase font-weight-bold",
                          class: { "text-active": _vm.active },
                        },
                        [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }