<template>
  <div v-if="schedules.length" class="animated fadeIn">
    <b-card body-class="p-0">
      <b-card-body class="p-0">
        <b-table responsive striped hover :items="schedules" :fields="fields" @row-clicked="onItemClicked">
          <template v-slot:cell(driver)="data">
            <span>{{ data.item.driver ? data.item.driver.name : 'Anônimo' }}</span>
          </template>

          <template v-slot:cell(booking)="data">
            <span>{{ data.item.booking ? (data.item.booking.status === 'ACTIVE' ? 'Aberto' : 'Fechado') : 'Sem locação' }}</span>
          </template>

          <template v-slot:cell(car)="data">
            <span>{{ data.item.car ? data.item.car.license_plate : 'Sem placa' }}</span>
          </template>

          <template v-slot:cell(scheduled_at)="data">
            {{ data.item.scheduled_at | moment('DD/MM/YYYY') }}
          </template>

          <template v-slot:cell(garage)="data">
            <span>{{ data.item.garage ? `${data.item.garage.name} - ${data.item.garage.address_state}` : '' }}</span>
          </template>

          <template v-slot:cell(odometer)="data">
            <span>{{ data.item | schedule_odometer_status }}</span>
          </template>

          <template v-slot:cell(status)="data">
            <b-btn v-if="isToSchedule(data.item)" variant="primary" data-test="driver-header_btn-schedule" @click="showCreateScheduleModal(data.item)">
              <i class="fa fa-calendar-plus-o" />
              Agendar manutenção
            </b-btn>
            <big-badge v-else :variant="data.item.status | schedule_status_to_bootstrap_variant">
              {{ data.item.status | schedule_status_to_human }}
            </big-badge>
          </template>
        </b-table>
      </b-card-body>
      <!-- <b-card-body class="p-0" v-if="wallet.errorType || wallet.items.length === 0">
        <content-controll :service="wallet"></content-controll>
      </b-card-body>-->
    </b-card>

    <!-- modal here -->
    <modal-create-schedule :driver="driver" :booking="booking" :schedule="schedule" :car="car" @scheduled="getListForCar" />

    <modal-update-schedule :schedule="schedule" @updated="getListForCar" />
  </div>
  <div v-else class="animated fadeIn">
    <b-card body-class="p-0">
      <b-card-body class="p-4">
        <h4 class="text-center">
          <span v-if="graphqlError" class="text-danger">{{ $t('maintenance.tables.error') }}</span>
          <span v-else>{{ $t('maintenance.tables.noSchedules') }}</span>
        </h4>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ScheduleStatusEnum from '@graphql/schedules/enum/status';
import BigBadge from '@components/shared/big-badge';
import ModalCreateSchedule from '@components/modals/create-schedule';
import ModalUpdateSchedule from '@components/modals/update-schedule';
import { mapGetters } from 'vuex';

export default {
  name: 'ScheduleTable',
  components: {
    BigBadge,
    ModalCreateSchedule,
    ModalUpdateSchedule,
  },
  props: {
    car: {
      type: Object,
      required: true,
    },
    driver: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    schedule: {},
    graphqlError: false,
    fields: [
      {
        key: 'driver',
        label: 'Motorista',
      },
      {
        key: 'booking',
        label: 'Locação',
      },
      {
        key: 'car',
        label: 'Carro',
      },
      {
        key: 'scheduled_at',
        label: 'Data agendada',
      },
      {
        key: 'garage',
        label: 'Oficina',
      },
      {
        key: 'odometer',
        label: 'Odômetro',
      },
      {
        key: 'status',
        label: 'Status',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      schedules: 'schedule/listForCar',
    }),
  },
  mounted() {
    this.getListForCar();
  },
  methods: {
    onItemClicked(schedule) {
      const scheduleIsToScheduleStatus = schedule.status === ScheduleStatusEnum.TO_SCHEDULE;

      if (scheduleIsToScheduleStatus) {
        return;
      }

      this.schedule = schedule;

      this.$root.$emit('bv::show::modal', 'modal-update-schedule');
    },

    async getListForCar() {
      try {
        await this.$store.dispatch('schedule/getListForCar', this.car.id);
        this.graphqlError = false;
      } catch (error) {
        this.graphqlError = true;
      }
    },

    isToSchedule(schedule) {
      return schedule.status === ScheduleStatusEnum.TO_SCHEDULE;
    },

    showCreateScheduleModal(schedule) {
      if (!schedule) {
        throw new Error('Schedule ID not received');
      }

      this.schedule = schedule;
      this.$root.$emit('bv::show::modal', 'modal-create-schedule');
    },
  },
};
</script>
