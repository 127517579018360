var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.schedules.length
    ? _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-card",
            { attrs: { "body-class": "p-0" } },
            [
              _c(
                "b-card-body",
                { staticClass: "p-0" },
                [
                  _c("b-table", {
                    attrs: {
                      responsive: "",
                      striped: "",
                      hover: "",
                      items: _vm.schedules,
                      fields: _vm.fields,
                    },
                    on: { "row-clicked": _vm.onItemClicked },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(driver)",
                          fn: function (data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    data.item.driver
                                      ? data.item.driver.name
                                      : "Anônimo"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(booking)",
                          fn: function (data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    data.item.booking
                                      ? data.item.booking.status === "ACTIVE"
                                        ? "Aberto"
                                        : "Fechado"
                                      : "Sem locação"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(car)",
                          fn: function (data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    data.item.car
                                      ? data.item.car.license_plate
                                      : "Sem placa"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(scheduled_at)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      data.item.scheduled_at,
                                      "DD/MM/YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(garage)",
                          fn: function (data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    data.item.garage
                                      ? `${data.item.garage.name} - ${data.item.garage.address_state}`
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(odometer)",
                          fn: function (data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("schedule_odometer_status")(
                                      data.item
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(status)",
                          fn: function (data) {
                            return [
                              _vm.isToSchedule(data.item)
                                ? _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        "data-test":
                                          "driver-header_btn-schedule",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showCreateScheduleModal(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-calendar-plus-o",
                                      }),
                                      _vm._v(" Agendar manutenção "),
                                    ]
                                  )
                                : _c(
                                    "big-badge",
                                    {
                                      attrs: {
                                        variant: _vm._f(
                                          "schedule_status_to_bootstrap_variant"
                                        )(data.item.status),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("schedule_status_to_human")(
                                              data.item.status
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      514406589
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("modal-create-schedule", {
            attrs: {
              driver: _vm.driver,
              booking: _vm.booking,
              schedule: _vm.schedule,
              car: _vm.car,
            },
            on: { scheduled: _vm.getListForCar },
          }),
          _c("modal-update-schedule", {
            attrs: { schedule: _vm.schedule },
            on: { updated: _vm.getListForCar },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-card",
            { attrs: { "body-class": "p-0" } },
            [
              _c("b-card-body", { staticClass: "p-4" }, [
                _c("h4", { staticClass: "text-center" }, [
                  _vm.graphqlError
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("maintenance.tables.error"))),
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("maintenance.tables.noSchedules"))
                        ),
                      ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }