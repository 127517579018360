var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "pdfViewerModal",
      attrs: {
        id: "pdfViewer",
        lazy: "",
        "no-fade": "",
        scrollable: "",
        centered: "",
        size: "xl",
        title: "Visualizador de PDF",
        "ok-title": "Fechar",
        "ok-only": "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-row align-items-stretch",
          staticStyle: { "min-height": "70vh", display: "flex" },
        },
        [
          _vm.documentUrl
            ? _c("pdf-viewer", { attrs: { src: _vm.documentUrl } })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }