var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-badge",
    {
      class: "big-badge " + _vm.variant,
      attrs: { variant: _vm.variant !== "others" ? _vm.variant : "" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }