var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "region-keep error" }, [
    _c("h1", [_vm._v(_vm._s(_vm.$t("shared.region.changeRegionErrorTitle")))]),
    _c("p", [
      _vm._v(_vm._s(_vm.$t("shared.region.regionFrom")) + " "),
      _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                `shared.region.regionGroupEnum.${_vm.displayRegion(
                  _vm.userRegion
                )}`
              )
            ) +
            " "
        ),
      ]),
    ]),
    _c("p", [
      _vm._v(_vm._s(_vm.$t("shared.region.regionTo")) + " "),
      _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                `shared.region.regionGroupEnum.${_vm.displayRegion(
                  _vm.entityRegion
                )}`
              )
            ) +
            " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }