<script>
export default {
    name: 'BigBadge',
    props: {
        variant: {
            type: String,
            default: ''
        }
    },
    data: () => ({}),
    
}
</script>

<style scoped>
  .big-badge {
    width:100%;
    height: 24px;
    line-height: 18px;
    text-transform: uppercase;
    
  }

  .others {
    background-color: #3e3f42;
    color: white;
  }

  .negotiation {
    background-color: orange;
    color: white;
  }

</style>

<template>
  <b-badge :class="'big-badge ' + variant" :variant="variant !== 'others' ? variant : ''">
    <slot />
  </b-badge>
</template>