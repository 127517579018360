<template>
  <div class="region-keep warning">
    <h1>{{ $t('shared.region.changeRegionWarningTitle') }}</h1>

    <p>{{ $t('shared.region.regionFrom') }}
      <span>
        {{ $t(`shared.region.regionGroupEnum.${displayRegion(userRegion)}`) }}
      </span>
    </p>

    <p>{{ $t('shared.region.regionTo') }}
      <span>
        {{ $t(`shared.region.regionGroupEnum.${displayRegion(entityRegion)}`) }}
      </span>
    </p>

    <b-button
      size="lg"
      title="Migrar"
      variant="primary"
      @click.stop="migrate"
    >
      {{ $t('shared.region.changeRegionButton') }} <i class="fa fa-arrow-right" />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'RegionWarning',
  props: {
    userGroups: {
      type: Array,
      default: () => [],
      required: true,
    },
    userRegion: {
      type: String,
      default: '',
      required: true,
    },
    entityRegion: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    migrate() {
      this.$root.$emit('setRegion', this.entityRegion)
    },
    displayRegion(string) {
      return string.split(':').pop();
    }
  }
}
</script>

<style lang="scss">
.region-keep.warning {
  background-image: url(/img/warning.svg);
}
</style>
