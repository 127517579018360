var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: `notify-box ${_vm.size}` }, [
    _vm.networkError
      ? _c("div", [
          _vm._m(0),
          _c(
            "h5",
            [
              _vm._t("error", function () {
                return [_vm._v(" Ocorreu um erro ao carregar esta página :( ")]
              }),
            ],
            2
          ),
          _c("h6", [_vm._v("Caso necessite de alguma ajuda, abra uma SI.")]),
        ])
      : _c("div", [
          _vm._m(1),
          _c("h5", [_vm._v(_vm._s(_vm.loadingMessage))]),
          _c("h6", [_vm._v(_vm._s(_vm.loadingSubMessage))]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("i", { staticClass: "fa fa-unlink" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("i", { staticClass: "fa fa-spin fa-refresh" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }