<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="6">
          <b-form-group :label="$t('maintenance.labels.updateScheduleStatus')" label-for="maintenance_kind">
            <b-select
              id="maintenance_kind"
              v-model="form.statusSchedule"
              data-test="maintenance_status-schedule"
              :options="listStatusSchedule"
              :disabled="disabledScheduleStatus"
            />
          </b-form-group>
        </b-col>

        <b-col v-show="canEditScheduleStatus" cols="3">
          <b-form-group :label="$t('maintenance.fields.odometer')" label-for="vehicle_odometer_input" style="color: transparent">
            <b-input-group append="Km">
              <b-input
                id="vehicle_odometer_input"
                data-test="vehicle-odometer-input"
                :readonly="readonly"
                :value="setKmFormat(form.vehicleOdometer)"
                v-model="form.vehicleOdometer"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group :label="$t('maintenance.labels.updateScheduleType')" label-for="maintenance_type">
            <b-select
              id="maintenance_type"
              v-model="form.maintenanceType"
              data-test="maintenance_type--select"
              :options="listMaintenanceType"
              :disabled="disabledFields"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group :label="$t('maintenance.labels.scheduleDate')" label-for="schedule_date">
            <date-picker v-model="form.scheduleDate" :hide="false" :not-before="getFirstDateOfCalendar" />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group :label="$t('maintenance.labels.scheduleHour')" label-for="schedule_hour">
            <b-select id="schedule_hour" v-model="form.scheduleHour" data-test="schedule_hour--select" :options="listHoursAvailable" />
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row class="mt-3">
        <b-col cols="12">
          <b-form-group :label="$t('maintenance.labels.garage')" label-for="garage">
            <b-select id="garage" v-model="form.garage" data-test="garage--select" :options="listGarage" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="6">
          <b-form-group :label="$t('maintenance.labels.kmMarcks')" label-for="km_marcks_id">
            <b-form-input
              id="km_marcks_id"
              type="range"
              min="1000"
              max="50000"
              value="50000"
              data-test="km_marcks_id"
              list="km_marcks"
              @input="onInputRangeUpdateDistance"
            />
          </b-form-group>

          <datalist id="km_marcks">
            <option value="1000" title="1 Km" label="1 Km" />
            <option value="5000" label="5 Km" />
            <option value="10000" label="10 Km" />
            <option value="15000" label="10 Km" />
            <option value="20000" label="10 Km" />
            <option value="25000" label="10 Km" />
            <option value="30000" label="10 Km" />
            <option value="35000" label="10 Km" />
            <option value="40000" label="10 Km" />
            <option value="45000" label="10 Km" />
            <option value="50000" label="10 Km" />
          </datalist>
        </b-col>

        <b-col cols="3">
          <b-form-group label="&nbsp;" style="color: transparent">
            <b-input-group prepend="Km">
              <b-input disabled :value="formatDistance" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row v-show="showInternalComments && !canEditScheduleStatus" class="mt-3">
        <b-col cols="12">
          <b-form-group :label="$t('maintenance.labels.updateScheduleComments')" label-for="internal_comments">
            <b-select
              id="internal_comments"
              v-model="form.internalComments"
              data-test="internal_comments--select"
              :options="listInternalComments"
              :disabled="disableInternalComment"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-show="showInternalComments && canEditScheduleStatus" class="mt-3">
        <b-col cols="12">
          <b-form-group :label="$t('maintenance.labels.updateScheduleComments')" label-for="schedule-obs-input">
            <b-form-textarea id="schedule-obs-input" v-model="form.internalComments" data-test="schedule-obs-input" :value="form.internalComments" />
            <!-- @update="dataInput('scheduleObs', $event)" -->
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { generateHours } from '@utils/list-hour';
import GarageStatusEnum from '@graphql/garages/enum/status';
import ScheduleStatusEnum from '@graphql/schedules/enum/status';
import ScheduleTypeEnum from '@graphql/schedules/enum/types';
import { listInternalComments } from '@utils/schedule';
import { formatNumber } from '@utils/numbers';

export default {
  name: 'UpdateSchedule',
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    listGarageByDistance: [],
    time: 0,
    garageDistance: 50000,
    readonly: false,
    form: {
      maintenanceType: ScheduleTypeEnum.PREVENTIVE,
      scheduleDate: '',
      scheduleHour: '09:00',
      garage: '',
      internalComments: '',
      statusSchedule: null,
      vehicleOdometer: null,
    },
  }),
  computed: {
    getFirstDateOfCalendar() {
      return this.$moment(this.schedule.scheduled_at).startOf('day').format();
    },
    listGarage() {
      const garageByDistance = this.listGarageByDistance.map(item => ({
        value: item.id,
        text: this.mountGarageText(item),
      }));

      const formGarage = {
        text: this.schedule.garage?.name || '',
        value: this.schedule.garage?.id,
      };

      return [formGarage, ...garageByDistance];
    },

    formatDistance() {
      if (this.garageDistance) {
        return this.garageDistance / 1000;
      }

      return this.garageDistance;
    },

    showInternalComments() {
      const listStatusToShowInternalComments = [ScheduleStatusEnum.NO_SHOW];

      return listStatusToShowInternalComments.includes(this.form.statusSchedule);
    },

    disableInternalComment() {
      const listDisableStatus = [ScheduleStatusEnum.DONE, ScheduleStatusEnum.NO_SHOW, ScheduleStatusEnum.DOING];

      return listDisableStatus.includes(this.schedule.status);
    },

    disabledFields() {
      const listDisableStatus = [ScheduleStatusEnum.DONE, ScheduleStatusEnum.NO_SHOW, ScheduleStatusEnum.DOING];

      if (listDisableStatus.includes(this.schedule.status)) {
        return true;
      }

      const scheduleAt = this.$moment(this.schedule.scheduled_at);

      return !this.$moment().isBefore(scheduleAt);
    },

    listInternalComments() {
      return listInternalComments;
    },

    disabledScheduleStatus() {
      if (this.checkScheduleView({ show: 'ACL:SCHEDULE:CHANGE_STATUS' })) return false;
      return ![ScheduleStatusEnum.TO_SCHEDULE, ScheduleStatusEnum.SCHEDULED].includes(this.schedule.status);
    },

    listStatusSchedule() {
      return Object.keys(ScheduleStatusEnum)
        .filter(status => [ScheduleStatusEnum.SCHEDULED, ScheduleStatusEnum.NO_SHOW, ScheduleStatusEnum.DONE, ScheduleStatusEnum.CANCELED].includes(status))
        .map(status => ({
          text: this.$t('maintenance.statusToHuman.' + status),
          value: status,
        }));
    },
    listHoursAvailable() {
      const listHours = generateHours(5, 18);

      return listHours.map(hour => ({
        text: hour,
        value: hour,
      }));
    },
    listMaintenanceType() {
      return [
        {
          text: this.$t('maintenance.scheduleTypeEnum.PREVENTIVE'),
          value: ScheduleTypeEnum.PREVENTIVE,
        },
        {
          text: this.$t('maintenance.scheduleTypeEnum.CORRECTIVE'),
          value: ScheduleTypeEnum.CORRECTIVE,
          disabled: true,
        },
      ];
    },
  },
  watch: {
    schedule() {
      this.getGarageByDistance();
      this.populateForm();
    },
  },
  beforeMount() {
    this.$store.dispatch('garage/getAll', {
      variables: {
        filter: {
          status: GarageStatusEnum.ACTIVE,
          schedule_types: {
            preventive: true,
          },
        },
      },
    });

    this.populateForm();
  },
  methods: {
    setKmFormat(value) {
      return formatNumber(value);
    },
    canEditScheduleStatus() {
      const listStatusToShowInternalComments = [ScheduleStatusEnum.CANCELED, ScheduleStatusEnum.DONE, ScheduleStatusEnum.NO_SHOW];
      return (
        listStatusToShowInternalComments.includes(this.form.statusSchedule) &&
        listStatusToShowInternalComments.includes(this.schedule.status) &&
        this.checkScheduleView({ show: 'ACL:SCHEDULE:CHANGE_STATUS' })
      );
    },
    dataInput(inputName, value) {
      this.$emit('change', {
        name: inputName,
        data: value,
      });
    },
    checkScheduleView(e) {
      const groups = this.$store.getters['user/groups'];
      if (e.show) {
        if (groups.includes(e.show)) return true;
        return false;
      }
      return true;
    },
    mountGarageText(garage) {
      return `[${this.writeCredential(garage.reseller_trusted)}] ${garage.name} - ${this.writeAddress(garage)}`;
    },

    writeCredential(resellerTrusted) {
      return resellerTrusted ? this.$t('maintenance.texts.resellerIsTrusted') : this.$t('maintenance.texts.resellerNotTrusted');
    },

    onInputRangeUpdateDistance(value) {
      if (!value) {
        return;
      }

      clearTimeout(this.time);

      this.garageDistance = value;

      this.time = setTimeout(() => {
        this.getGarageByDistance();
      }, 500);
    },

    async getGarageByDistance() {
      if (!this.schedule.driver) {
        return;
      }

      const latLong = this.schedule.driver.address_latlong.split(',').map(position => parseFloat(position));
      const distance = parseInt(this.garageDistance, 10);

      const garages = await this.$store.dispatch('garage/findByDistance', {
        latLong,
        distance,
        filter: {
          schedule_types: {
            preventive: true,
          },
        },
      });

      this.listGarageByDistance = garages;
    },

    populateForm() {
      if (!Object.keys(this.schedule).length) {
        return;
      }

      const scheduledAt = this.$moment(this.schedule.scheduled_at);
      const garage = this.schedule.garage ? this.schedule.garage.id : null;

      this.form = {
        maintenanceType: this.schedule.type,
        scheduleHour: scheduledAt.format('HH:mm'),
        scheduleDate: `${scheduledAt.format('YYYY-MM-DD')} 15:00:00`,
        internalComments: this.schedule.comments,
        statusSchedule: this.schedule.status,
        vehicleOdometer: this.schedule.metadata[`odometer_at_${this.schedule.status}`] || this.getOdometer(),
        garage,
      };
    },

    writeAddress(address) {
      const { address_street_name, address_street_number, address_city, address_neighborhood, address_state, address_country } = address;

      return `Filial de ${address_neighborhood} | ${address_street_name}
      ${address_street_number}, ${address_city}, ${address_state} - ${address_country}`;
    },

    wrapperUpdateSchedule() {
      switch (this.form.statusSchedule) {
        case ScheduleStatusEnum.SCHEDULED:
          this.reScheduleItAlert();
          break;

        case ScheduleStatusEnum.NO_SHOW:
          this.didNotAttendAlert();
          break;

        case ScheduleStatusEnum.DONE:
          this.finishItAlert();
          break;
        case ScheduleStatusEnum.CANCELED:
          this.cancelItAlert();
          break;
      }
    },

    reScheduleItAlert() {
      const configSwal = {
        type: 'warning',
        title: 'Deseja realizar o reagendamento?',
        showConfirmButton: true,
        confirmButtonText: 'Alterar',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
        showLoaderOnConfirm: true,
        preConfirm: () => this.reScheduleIt(),
      };
      this.$swal(configSwal)
        .then(result => {
          if (result.dismiss) {
            return;
          }

          this.showSuccessAlert('Sucesso ao reagendar manutenção');
        })
        .catch(error => {
          this.$log.logError(error);
          this.showFailAlert();
        });
    },

    reScheduleIt() {
      const dateRaw = this.$moment(this.form.scheduleDate).format('YYYY-MM-DD');
      let date = this.$moment(`${dateRaw} ${this.form.scheduleHour}:00`);

      date.utc();
      date = date.format('YYYY-MM-DD HH:mm');

      return this.$store
        .dispatch('schedule/reScheduleIt', {
          scheduleId: this.schedule.id,
          date,
          garage: this.form.garage,
        })
        .then(result => {
          this.$emit('updated', result);
          return result;
        });
    },

    didNotAttendAlert() {
      const configSwal = {
        type: 'warning',
        title: 'Motorista não compareceu?',
        text: 'Ao realizar esta ação o status do agendamento mudará para "Motorista não compareceu".',
        showConfirmButton: true,
        confirmButtonText: 'Alterar',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
        showLoaderOnConfirm: true,
        preConfirm: () => this.didNotAttend(),
      };
      this.$swal(configSwal)
        .then(result => {
          if (result.dismiss) {
            return;
          }

          this.showCycleBackAlert();
        })
        .catch(error => {
          this.$log.logError(error);
          this.showFailAlert();
        });
    },

    async didNotAttend() {
      let storeFunction = 'schedule/didNotAttend';
      if (this.canEditScheduleStatus()) {
        storeFunction = 'schedule/changeScheduleStatus';
      }
      return this.$store
        .dispatch(storeFunction, {
          variables: {
            scheduleId: this.schedule.id,
            comments: this.form.internalComments,
            odometer: await this.getOdometer(),
            status: 'NO_SHOW',
          },
        })
        .then(result => {
          this.$emit('updated', result);
          return result;
        });
    },

    showCycleBackAlert() {
      const configSwalSuccess = {
        type: 'success',
        title: `Agendamento atualizado!`,
        text: 'Agendamento foi atualizado para "Motorista não compareceu". Deseja reagendar?',
        showConfirmButton: true,
        confirmButtonText: 'Novo agendamento',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
        showLoaderOnConfirm: true,
        preConfirm: () => this.cycleBackForSchedule(),
      };

      this.$swal(configSwalSuccess)
        .then(result => {
          if (result.dismiss) {
            return;
          }

          this.showSuccessAlert('Sucesso ao reagendar', 'Foi criado um novo agendamento para este motorista.');
        })
        .catch(error => {
          this.$log.logError(`fail on cycle back for schedule: ${JSON.stringify(error)}`);
          this.showFailAlert();
        });
    },

    async cycleBackForSchedule() {
      return this.$store
        .dispatch('schedule/cycleBack', {
          variables: {
            scheduleId: this.schedule.id,
            odometer: await this.getOdometer(),
          },
        })
        .then(result => {
          this.$emit('updated', result);
          return result;
        });
    },

    finishItAlert() {
      const configSwal = {
        type: 'warning',
        title: 'Agendamento finalizado?',
        text: 'Ao realizar esta ação o agendamento mudará para pronto.',
        showConfirmButton: true,
        confirmButtonText: 'Finalizar',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
        showLoaderOnConfirm: true,
        preConfirm: () => this.finishIt(),
      };
      this.$swal(configSwal)
        .then(result => {
          if (result.dismiss) {
            return;
          }

          this.showSuccessAlert('Manutenção realizada com sucesso.', 'Agendamento finalizado!');
        })
        .catch(error => {
          this.$log.logError(error);
          this.showFailAlert();
        });
    },

    async finishIt() {
      let storeFunction = 'schedule/finishIt';
      if (this.canEditScheduleStatus()) {
        storeFunction = 'schedule/changeScheduleStatus';
      }

      return this.$store
        .dispatch(storeFunction, {
          variables: {
            scheduleId: this.schedule.id,
            comments: this.form.internalComments,
            odometer: await this.getOdometer(),
            status: 'DONE',
          },
        })
        .then(result => {
          this.$emit('updated', result);
          return result;
        });
    },

    cancelItAlert() {
      const configSwal = {
        type: 'warning',
        title: 'Agendamento cancelado?',
        text: 'Ao realizar esta ação o agendamento mudará para cancelado.',
        showConfirmButton: true,
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
        showLoaderOnConfirm: true,
        preConfirm: () => this.cancelIt(),
      };
      this.$swal(configSwal)
        .then(result => {
          if (result.dismiss) {
            return;
          }

          this.showSuccessAlert('Manutenção cancelada com sucesso.', 'Agendamento cancelado!');
        })
        .catch(error => {
          this.$log.logError(error);
          this.showFailAlert();
        });
    },

    async cancelIt() {
      let storeFunction = 'schedule/cancelIt';
      if (this.canEditScheduleStatus()) {
        storeFunction = 'schedule/changeScheduleStatus';
      }

      return this.$store
        .dispatch(storeFunction, {
          variables: {
            scheduleId: this.schedule.id,
            comments: this.form.internalComments,
            odometer: await this.getOdometer(),
            status: 'CANCELED',
          },
        })
        .then(result => {
          this.$emit('updated', result);
          return result;
        });
    },

    showSuccessAlert(title, text) {
      const configSwalSuccess = {
        type: 'success',
        title,
        text,
        showConfirmButton: true,
        confirmButtonText: 'Fechar',
        onClose: () => this.$root.$emit('bv::hide::modal', 'modal-update-schedule'),
      };

      this.$swal(configSwalSuccess);
    },

    showFailAlert() {
      const configSwalFail = {
        type: 'error',
        title: `Não foi possível alterar o agendamento`,
        text: '',
      };

      this.$swal(configSwalFail);
    },

    getOdometer() {
      const formOdometer = this.parseOdometer(this.form.vehicleOdometer);
      const scheduleStatusKey = `odometer_at_${this.schedule.status}`;
      const scheduleOdometer = this.schedule.metadata[scheduleStatusKey];

      if (formOdometer !== null && formOdometer !== scheduleOdometer) {
        return formOdometer;
      }

      return this.$store
        .dispatch('fleet/getByLicensePlate', {
          license_plate: this.schedule.car.license_plate,
        })
        .then(({ data }) => data.carTracking.odometer)
        .catch(error => {
          this.$log.logError(`error to get odometer on update schedule: ${error.message}`);

          return null;
        });
    },
    parseOdometer(odometer) {
      const parsedOdometer = parseInt(odometer);
      return isNaN(parsedOdometer) ? null : parsedOdometer;
    },
  },
};
</script>
