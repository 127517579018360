<template>
  <b-modal
    id="modal-create-schedule"
    ref="modalCreateSchedule"
    size="lg"
    :title="$t('maintenance.titles.modalCreateSchedule')"
    :ok-title="$t('maintenance.titles.modalCreateScheduleOk')"
    :cancel-title="$t('maintenance.buttons.close')"
    @ok.prevent="createSchedule"
    @close="resetSchedule"
  >
    <create-schedule
      ref="createScheduleComponent"
      :booking="booking"
      :car="car"
      :driver="driver"
      :schedule="schedule"
      @scheduled="(schedule) => $emit('scheduled', schedule)"
    />
  </b-modal>
</template>

<script>
import CreateSchedule from '@components/schedule/create-schedule';

export default {
  name: 'ModalCreateSchedule',
  components: {
    CreateSchedule,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    driver: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    car: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    currentDisplay: null,
  }),
  methods: {
    createSchedule() {
      this.$refs.createScheduleComponent.newSchedule();
    },
    resetSchedule() {
      this.$refs.createScheduleComponent.reset();
    }
  },
};
</script>
