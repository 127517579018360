<template>
  <b-modal
    id="pdfViewer"
    ref="pdfViewerModal"
    lazy
    no-fade
    scrollable
    centered
    size="xl"
    title="Visualizador de PDF"
    ok-title="Fechar"
    ok-only
  >
    <div class="d-flex flex-row align-items-stretch" style="min-height: 70vh; display: flex;">
      <pdf-viewer v-if="documentUrl" :src="documentUrl" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PdfViewerModal',
  components: {
    PdfViewer: () => import('@components/pdf-viewer'),
  },
  props: {
    documentUrl: {
      type: String,
      required: false,
      default: ''
    },
  },
  methods: {
    show() {
      this.$refs.pdfViewerModal.show();
    },
    hide() {
      this.$refs.pdfViewerModal.hide();
    },
  }
}
</script>
