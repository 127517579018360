var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "maintenance.labels.updateScheduleStatus"
                        ),
                        "label-for": "maintenance_kind",
                      },
                    },
                    [
                      _c("b-select", {
                        attrs: {
                          id: "maintenance_kind",
                          "data-test": "maintenance_status-schedule",
                          options: _vm.listStatusSchedule,
                          disabled: _vm.disabledScheduleStatus,
                        },
                        model: {
                          value: _vm.form.statusSchedule,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "statusSchedule", $$v)
                          },
                          expression: "form.statusSchedule",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canEditScheduleStatus,
                      expression: "canEditScheduleStatus",
                    },
                  ],
                  attrs: { cols: "3" },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticStyle: { color: "transparent" },
                      attrs: {
                        label: _vm.$t("maintenance.fields.odometer"),
                        "label-for": "vehicle_odometer_input",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { append: "Km" } },
                        [
                          _c("b-input", {
                            attrs: {
                              id: "vehicle_odometer_input",
                              "data-test": "vehicle-odometer-input",
                              readonly: _vm.readonly,
                              value: _vm.setKmFormat(_vm.form.vehicleOdometer),
                            },
                            model: {
                              value: _vm.form.vehicleOdometer,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vehicleOdometer", $$v)
                              },
                              expression: "form.vehicleOdometer",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("maintenance.labels.updateScheduleType"),
                        "label-for": "maintenance_type",
                      },
                    },
                    [
                      _c("b-select", {
                        attrs: {
                          id: "maintenance_type",
                          "data-test": "maintenance_type--select",
                          options: _vm.listMaintenanceType,
                          disabled: _vm.disabledFields,
                        },
                        model: {
                          value: _vm.form.maintenanceType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maintenanceType", $$v)
                          },
                          expression: "form.maintenanceType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("maintenance.labels.scheduleDate"),
                        "label-for": "schedule_date",
                      },
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          hide: false,
                          "not-before": _vm.getFirstDateOfCalendar,
                        },
                        model: {
                          value: _vm.form.scheduleDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "scheduleDate", $$v)
                          },
                          expression: "form.scheduleDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("maintenance.labels.scheduleHour"),
                        "label-for": "schedule_hour",
                      },
                    },
                    [
                      _c("b-select", {
                        attrs: {
                          id: "schedule_hour",
                          "data-test": "schedule_hour--select",
                          options: _vm.listHoursAvailable,
                        },
                        model: {
                          value: _vm.form.scheduleHour,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "scheduleHour", $$v)
                          },
                          expression: "form.scheduleHour",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("maintenance.labels.garage"),
                        "label-for": "garage",
                      },
                    },
                    [
                      _c("b-select", {
                        attrs: {
                          id: "garage",
                          "data-test": "garage--select",
                          options: _vm.listGarage,
                        },
                        model: {
                          value: _vm.form.garage,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "garage", $$v)
                          },
                          expression: "form.garage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("maintenance.labels.kmMarcks"),
                        "label-for": "km_marcks_id",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "km_marcks_id",
                          type: "range",
                          min: "1000",
                          max: "50000",
                          value: "50000",
                          "data-test": "km_marcks_id",
                          list: "km_marcks",
                        },
                        on: { input: _vm.onInputRangeUpdateDistance },
                      }),
                    ],
                    1
                  ),
                  _c("datalist", { attrs: { id: "km_marcks" } }, [
                    _c("option", {
                      attrs: { value: "1000", title: "1 Km", label: "1 Km" },
                    }),
                    _c("option", { attrs: { value: "5000", label: "5 Km" } }),
                    _c("option", { attrs: { value: "10000", label: "10 Km" } }),
                    _c("option", { attrs: { value: "15000", label: "10 Km" } }),
                    _c("option", { attrs: { value: "20000", label: "10 Km" } }),
                    _c("option", { attrs: { value: "25000", label: "10 Km" } }),
                    _c("option", { attrs: { value: "30000", label: "10 Km" } }),
                    _c("option", { attrs: { value: "35000", label: "10 Km" } }),
                    _c("option", { attrs: { value: "40000", label: "10 Km" } }),
                    _c("option", { attrs: { value: "45000", label: "10 Km" } }),
                    _c("option", { attrs: { value: "50000", label: "10 Km" } }),
                  ]),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticStyle: { color: "transparent" },
                      attrs: { label: "&nbsp;" },
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { prepend: "Km" } },
                        [
                          _c("b-input", {
                            attrs: { disabled: "", value: _vm.formatDistance },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showInternalComments && !_vm.canEditScheduleStatus,
                  expression: "showInternalComments && !canEditScheduleStatus",
                },
              ],
              staticClass: "mt-3",
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "maintenance.labels.updateScheduleComments"
                        ),
                        "label-for": "internal_comments",
                      },
                    },
                    [
                      _c("b-select", {
                        attrs: {
                          id: "internal_comments",
                          "data-test": "internal_comments--select",
                          options: _vm.listInternalComments,
                          disabled: _vm.disableInternalComment,
                        },
                        model: {
                          value: _vm.form.internalComments,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "internalComments", $$v)
                          },
                          expression: "form.internalComments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showInternalComments && _vm.canEditScheduleStatus,
                  expression: "showInternalComments && canEditScheduleStatus",
                },
              ],
              staticClass: "mt-3",
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "maintenance.labels.updateScheduleComments"
                        ),
                        "label-for": "schedule-obs-input",
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "schedule-obs-input",
                          "data-test": "schedule-obs-input",
                          value: _vm.form.internalComments,
                        },
                        model: {
                          value: _vm.form.internalComments,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "internalComments", $$v)
                          },
                          expression: "form.internalComments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }