var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "region-guard" },
    [
      _vm.sameRegion
        ? _vm._t("default")
        : _vm.entityRegion
        ? _c(
            "div",
            [
              _c(
                _vm.getRegionGuard,
                _vm._b(
                  { tag: "component" },
                  "component",
                  _vm.componentProps,
                  false
                )
              ),
            ],
            1
          )
        : _c("div", [_c("content-loading")], 1),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }