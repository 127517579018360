<template>
  <div class="region-guard">
    <slot v-if="sameRegion" />
    <div v-else-if="entityRegion">
      <component
        :is="getRegionGuard"
        v-bind="componentProps"
      />
    </div>
    <div v-else>
      <content-loading />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RegionError from './region-error'
import RegionWarning from './region-warning'
import ContentLoading from '@components/content-loading';

export default {
  name: 'RegionGuard',
  components: {
    ContentLoading
  },
  props: {
    entity: {
      type: String,
      default: '',
      required: true,
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    hasRegion: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      entityRegion: '',
    };
  },
  computed: {
    getRegionGuard() {
      return this.userGroups.includes(this.entityRegion)
        ? RegionWarning
        : RegionError;
    },
    ...mapGetters('user', {
      userRegion: 'region' ,
      userGroups: 'groups' ,
    }),
    sameRegion() {
      if (!this.entityRegion || !this.hasRegion) return false;

      return this.userRegion === this.entityRegion;
    },
    componentProps() {
      return {
        userGroups: this.userGroups,
        userRegion: this.userRegion,
        entityRegion: this.entityRegion,
      }
    }
  },
  async beforeMount() {
    const { group } = await this.fetchEntityRegion({
      entity: this.entity,
      id: this.id,
    });

    this.entityRegion = group;
  },
  methods: {
    ...mapActions({
      fetchEntityRegion: 'user/fetchEntityRegion'
    }),
  },
}
</script>


<style lang="scss">
.region-keep {
  font-family: sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: #838383;
  text-align: center;
  padding: 290px 0 60px 0;
  margin-top: 100px;
  background: transparent center 0 no-repeat;
  background-size: auto 45%;

  h1 {
    margin: 2rem auto;
    color: #000;
  }

  span {
    color: #000;
  }

  button {
    margin-top: 1.5rem;
  }
}
</style>
