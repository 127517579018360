<template>
  <b-modal
    id="modal-update-schedule"
    ref="modalCreateSchedule"
    size="lg"
    :title="$t('maintenance.titles.modalUpdateSchedule')"
    :ok-title="$t('maintenance.buttons.updateScheduleOkTitle')"
    :cancel-title="$t('maintenance.buttons.close')"
    @ok.prevent="handleOkClick"
  >
    <update-schedule ref="updateScheduleComponent" :schedule="schedule" @updated="schedule => $emit('updated', schedule)" />
  </b-modal>
</template>

<script>
import UpdateSchedule from '@components/schedule/update-schedule';
import swal from 'sweetalert2';

export default {
  name: 'ModalUpdateSchedule',
  components: {
    UpdateSchedule,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },

  methods: {
    updateSchedule() {
      this.$refs.updateScheduleComponent.wrapperUpdateSchedule();
    },
    enabledUpdate() {
      return this.$store.getters['user/groups'].includes('ACL:SCHEDULE:CHANGE_STATUS');
    },
    handleOkClick() {
      if (this.enabledUpdate()) {
        this.updateSchedule();
      } else {
        this.showErrorModal(this.$t('maintenance.texts.deniedAccessError'));
      }
    },
    showErrorModal(msg) {
      swal({
        type: 'error',
        title: this.$t('maintenance.titles.alertError'),
        text: msg,
        showConfirmButton: false,
        timer: 4000,
      });
    },
  },
};
</script>
