<template>
  <div class="region-keep error">
    <h1>{{ $t('shared.region.changeRegionErrorTitle') }}</h1>

    <p>{{ $t('shared.region.regionFrom') }}
      <span>
        {{ $t(`shared.region.regionGroupEnum.${displayRegion(userRegion)}`) }}
      </span>
    </p>

    <p>{{ $t('shared.region.regionTo') }}
      <span>
        {{ $t(`shared.region.regionGroupEnum.${displayRegion(entityRegion)}`) }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'RegionError',
  props: {
    userGroups: {
      type: Array,
      default: () => [],
      required: true,
    },
    userRegion: {
      type: String,
      default: '',
      required: true,
    },
    entityRegion: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    displayRegion(string) {
      return string.split(':').pop();
    }
  },
}
</script>

<style lang="scss">
.region-keep.error {
  background-image: url(/img/error.svg);
}
</style>
