<script>
export default {
  name: 'CardWidget',
  components: {},
  props: {
    to: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    subtitleHover: {
      type: 'object',
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'icon-people',
    },
    id: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      progress: 0,
      variant: 'primary',
    };
  },
  computed: {
    getId() {
      if(this.id) {
        return this.id
      } else {
        const name = this.title.toLowerCase().replace(' ', '-');
        return `card-${name}`;
      }
    },
  },
  methods: {
    goTo(link) {
      if (!link || this.$route.path === link) return
      this.$router.push({ path: link });
    },
  },
};
</script>

<template>
  <b-card
    :id="getId"
    class="card-pointer"
    :class="{ 'card-active': active }"
    @click="goTo(to)"
  >
    <b-row>
      <b-col cols="8" :title="title">
        <div class="h4 mb-0 font-weight-bold card-value">
          {{ value }}
        </div>
        <small
          class="text-muted text-uppercase font-weight-bold"
          :class="{ 'text-active': active }"
        >
          {{ title }}
        </small>
      </b-col>
      <b-col cols="4" class="text-muted text-right mb-0"  :class="{ 'text-center': subtitle }">
        <div v-b-popover.hover="subtitleHover" v-if="subtitle">
        <div class="h4 mb-0 font-weight-bold card-value">
          <i :class="{ [icon]: icon, 'icon-active': active, 'h3': !subtitle }" />
        </div>
        <small
          class="text-muted text-uppercase font-weight-bold"
          :class="{ 'text-active': active }"
        >
          {{ subtitle }}
        </small>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<style scoped lang="scss">
.card-pointer {
  cursor: pointer;
}
.card-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-active {
  background-color: #1b8eb7;
  color: #fff;
  small {
    color: #fff !important;
  }
}
.icon-active {
  color: #fff;
}
</style>
