var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalCreateSchedule",
      attrs: {
        id: "modal-update-schedule",
        size: "lg",
        title: _vm.$t("maintenance.titles.modalUpdateSchedule"),
        "ok-title": _vm.$t("maintenance.buttons.updateScheduleOkTitle"),
        "cancel-title": _vm.$t("maintenance.buttons.close"),
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOkClick.apply(null, arguments)
        },
      },
    },
    [
      _c("update-schedule", {
        ref: "updateScheduleComponent",
        attrs: { schedule: _vm.schedule },
        on: { updated: (schedule) => _vm.$emit("updated", schedule) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }