<template>
  <div>
    <p>
      <i class="fa fa-lock" aria-hidden="true" />
      {{ $i18n.t('fleet.titles.blocks') }}:
      <strong>{{ getBlocks(trackerData.flags.vehicleLockedSafe || false, trackerData.flags.vehicleLockedByServer, trackerData.flags.vehicleLockedByToken) }}</strong>
    </p>
    <p>
      <i class="fa fa-cog" aria-hidden="true" />
      {{ $i18n.t('fleet.texts.tokenRegistered') }}: <strong>{{ !trackerData.flags.tokenRegistered ? $i18n.t('fleet.texts.no') : $i18n.t('fleet.texts.yes') }}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'BlocksInfo',
  props: {
    trackerData: Object
  },
  computed: {},
  methods: {
    getBlocks(lockedSafe, lockedServer, lockedToken) {
      if (lockedSafe) return this.$i18n.t('fleet.texts.waitingSafedConditions');
      if (lockedServer) return this.$i18n.t('fleet.texts.lockedByCommand');
      if (lockedToken) return this.$i18n.t('fleet.texts.lockedByToken');
      return this.$i18n.t('fleet.texts.unlocked');
    },
  },
};
</script>
