var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "region-keep warning" },
    [
      _c("h1", [
        _vm._v(_vm._s(_vm.$t("shared.region.changeRegionWarningTitle"))),
      ]),
      _c("p", [
        _vm._v(_vm._s(_vm.$t("shared.region.regionFrom")) + " "),
        _c("span", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  `shared.region.regionGroupEnum.${_vm.displayRegion(
                    _vm.userRegion
                  )}`
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c("p", [
        _vm._v(_vm._s(_vm.$t("shared.region.regionTo")) + " "),
        _c("span", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  `shared.region.regionGroupEnum.${_vm.displayRegion(
                    _vm.entityRegion
                  )}`
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "b-button",
        {
          attrs: { size: "lg", title: "Migrar", variant: "primary" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.migrate.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("shared.region.changeRegionButton")) + " "
          ),
          _c("i", { staticClass: "fa fa-arrow-right" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }